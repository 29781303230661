var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { attrs: { id: "checkout-slide" } }, [
    !_vm.hideCart
      ? _c("div", [
          _c(
            "div",
            { staticClass: "checkout-intro-container" },
            [
              _c("slide-heading", {
                staticClass: "intro-heading mt-0",
                attrs: { heading: _vm.slide.heading },
              }),
              _c("p", { staticClass: "intro-content" }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.slide.description) + "\n      "
                ),
              ]),
            ],
            1
          ),
          _c("div", { staticClass: "checkout-cart-container" }, [
            _c(
              "div",
              [
                _c("checkout-cart", {
                  staticClass: "checkout-cart",
                  attrs: { bus: _vm.bus, "show-tos-url": true },
                  on: {
                    submit: _vm.nextSlide,
                    "register-company-order-verification": function ($event) {
                      _vm.foreignQualificationPitch = true
                    },
                  },
                }),
              ],
              1
            ),
          ]),
        ])
      : _c("h5", { staticClass: "w-100 text-center" }, [
          _vm._v(
            "\n    There are no products currently available for checkout.\n  "
          ),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }